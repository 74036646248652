import React from 'react';
import { FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    color     : muiTheme.palette.text.primary,
    fontSize  : muiTheme.typography.size.xs,
    lineHeight: '1.7',
  },
}));

const Label = props => {
  const classes = useStyles();
  return (
    <FormLabel data-element={props['data-element']} classes={{ root: classes.root }}>
      {props.children}
    </FormLabel>
  );
};

export default Label;
