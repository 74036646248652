import React from 'react';
import { createGlobalStyle } from 'styled-components';
import DatePicker from 'react-datepicker';

const DatePickerStyle = createGlobalStyle`
    .react-datepicker {
        border: 1px solid #abb9cb; // theming needed!
        border-radius: 4px;
        display: flex;
        flex-direction: row;
    }

    .react-datepicker__time-container {
        border-left: 1px solid #abb9cb; // theming needed!
        width: 70px;
    }

    .react-datepicker__month-container {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        white-space: nowrap;
        flex-grow: 1;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name,
    .react-datepicker__week-number{
        color: #212121; // theming needed!
        padding: 5px;
        width: 100%;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-datepicker__week-number {
        color: #ccc;
    }

    .react-datepicker__month {
        margin: 0.4rem;
        text-align: center;
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: space-around;
    }

    .react-datepicker__header {
        text-align: center;
        background-color: #fafafa;
        border-bottom: 1px solid #abb9cb; // theming needed!
        border-top-left-radius: 04px;
        border-top-right-radius: 0;
        padding-top: 8px;
        position: relative;
    }

    .react-datepicker__header--time {
        padding-bottom: 8px;
        padding-left: 5px;
        padding-right: 5px;
        border-top-left-radius: 0;
        border-top-right-radius: 04px;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
        margin-top: 0;
        color: #212121; // theming needed!
        font-weight: bold;
        font-size: 0.944rem;
    }

    .react-datepicker-time__header {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .react-datepicker__navigation {
        background: none;
        line-height: 1.7rem;
        text-align: center;
        cursor: pointer;
        position: absolute;
        top: 10px;
        width: 0;
        padding: 0;
        border: 0.45rem solid transparent;
        z-index: 1;
        height: 10px;
        width: 10px;
        text-indent: -999em;
        overflow: hidden;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
        border-right-color: #ccc;
    }

    .react-datepicker__navigation--previous:hover {
        border-right-color: #b3b3b3;
    }

    .react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
        border-right-color: #e6e6e6;
        cursor: default;
    }

    .react-datepicker__navigation--next {
        right: 10px;
        border-left-color: #ccc;
    }

    .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
        right: 80px;
    }

    .react-datepicker__navigation--next:hover {
        border-left-color: #b3b3b3;
    }

    .react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
        border-left-color: #e6e6e6;
        cursor: default;
    }

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #abb9cb; // theming needed!
  border-radius: 04px;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  //height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: rgba(0, 0, 0, 0.08);
  color: #212121;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box
  ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}



.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 04px;
  background-color: #f0f0f0;
}

.react-datepicker__day {
    cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 04px;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 04px;
  background-color: rgba(0, 0, 0, 0.08);
  color: #212121;
}

.react-datepicker__day--highlighted:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 04px;
  background-color: rgba(0, 0, 0, 0.08);
  color: #212121;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #212121; // theming needed!
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 04px;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 04px;
  border: 1px solid #abb9cb; // theming needed!
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 04px;
  border-top-right-radius: 0;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 04px;
  border-bottom-right-radius: 04px;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}
`;

export default props => (
  <React.Fragment>
    <DatePickerStyle />
    <DatePicker {...props} />
  </React.Fragment>
);
