import React from 'react';
import ValidatedDatepicker from './ValidatedDatepicker';

/**
 * Date yyyy-mm-dd hh:mm:ss
 * @param {*} props
 */
const Date_YYYYMMDD_HHMMSS_Field = props => {
  return (
    <ValidatedDatepicker
      {...props}
      expression={/^\d{4}(-)(((0)[1-9])|((1)[0-2]))(-)(([1-2][0-9])|([0-2][1-9])|(3)[0-1]) ([0-1][0-9]|[2][0-3]):([0-5][0-9]:[0-5][0-9])$/gm}
      format="YYYY-MM-DD HH:mm:ss"
      showTimeSelect
      showTimeSelectOnly={false}
    />
  );
};

export default Date_YYYYMMDD_HHMMSS_Field;