import { Snackbar as MuiSnackbar, SnackbarContent as MuiSnackbarContent } from '@material-ui/core';
import { makeStyles } from '../styles';
import IconButton from './IconButton';
import Icon from './Icon';
import React, { useState, useEffect } from 'react';

export const Snackbar = props => {
  const _options = Object.assign(
    {
      icon    : '',
      message : '',
      lifespan: 5000,
      autoShow: true,
    },
    props.options,
  );

  const shown = _options.autoShow && !_options.forceClose;

  useEffect(() => {
    if (_options.forceClose) {
      onExit();
    }
  });

  const onExit = () => {
    props.onSnackbarExit && props.onSnackbarExit();
  };

  return <SnackbarElement onExited={() => onExit()} shown={shown} {..._options} />;
};

const useStyles = makeStyles(theme => ({
  snackbar_bottom: {
    bottom                                : '15px',
    zIndex                                : '999999',
    '@media screen and (max-width: 960px)': {
      bottom: '50px',
      width : '96%',
      margin: '0 auto',
    },
  },
  snackbar_top: {
    top                                   : '15px',
    zIndex                                : '999999',
    bottom                                : 'auto',
    '@media screen and (max-width: 960px)': {
      top   : '50px',
      width : '96%',
      margin: '0 0',
    },
  },
  content: {
    backgroundColor: theme.palette.common.white,
    borderRadius   : theme.dimensions.borderRadiusPanel,
    borderLeftWidth: theme.dimensions.borderRadiusPanel,
    borderLeftStyle: 'solid',
    borderLeftColor: theme.palette.primary.main,
    boxShadow      : theme.dimensions.shadowPanel
  },
  message: {
    color     : theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  icon: {
    fontSize     : theme.typography.size.xl,
    verticalAlign: 'middle',
    marginRight  : '0.5em',
  },
}));

const SnackbarElement = props => {
  const [state, setState] = useState({
    icon    : props.icon,
    lifespan: props.lifespan,
    message : props.message,
    shown   : props.shown,
  });
  const classes = useStyles();

  const _handleClose = (e, reason) => {
    if (reason === 'clickaway') {
      // We do not want to hide snackbars when the user clicks somewhere else.
      return;
    }
    setState({ shown: false });
    props.onExited && props.onExited();
  };

  useEffect(() => {
    const timeOut = window.setTimeout(() => {
      setState({ ...state, shown: false });
    }, state.lifespan);
    return () => {
      window.clearTimeout(timeOut);
    };
  }, []);

  const message = (
    <span className={classes.message}>
      {state.icon && <Icon className={classes.icon}>{state.icon}</Icon>}
      {state.message}
    </span>
  );

  const getSnackbarClassName = () => {
    switch (props?.position) {
    case 'top': {
      return classes.snackbar_top;
    }
    case 'bottom': {
      return classes.snackbar_bottom;
    }
    default: {
      return classes.snackbar_bottom;
    }
    }
  };

  return (
    <MuiSnackbar
      className={getSnackbarClassName()}
      open={state.shown}
      autoHideDuration={state.lifespan}
      onClose={_handleClose}
      // onExited={() => props.onExited()}
    >
      <MuiSnackbarContent
        className={classes.content}
        message={message}
        action={[
          <IconButton key="close" color="primary" onClick={_handleClose}>
            <Icon>close</Icon>
          </IconButton>,
        ]}
      />
    </MuiSnackbar>
  );
};
