import { Container as MuiContainer } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { forwardRef } from 'react';

const useStyles = makeStyles({
  root: {
    maxWidth: 'none',
    width   : 'auto',
  },
});

const Container = forwardRef((props, ref) => {
  const classes = useStyles();
  const passProps = { ...props };
  delete passProps.key;
  delete passProps.initialWidth;
  delete passProps.animationTime;

  return (
    <MuiContainer classes={{ root: classes.root }} disableGutters ref={ref} {...passProps}>
      {props.children}
    </MuiContainer>
  );
});

export default Container;
