import React from 'react';
import { Tab as MuiTab, Tabs as MuiTabs } from '@material-ui/core';
import { tabsOverflowButtonFactory } from './TabsOverflowButton';
import { makeStyles } from '@material-ui/core/styles';

const useStylesSubTabs = makeStyles(theme => ({
  root: {
    minHeight           : theme.dimensions.inputHeight,
    position            : 'relative',
    borderTopLeftRadius : 0,
    borderTopRightRadius: 0,
    boxShadow           : `inset 0px -1px ${theme.palette.divider}`,
    backgroundColor     : theme.palette.neutral[50],
  },
}));

export function SubTabs(props) {
  const classes = useStylesSubTabs();
  return (
    <MuiTabs
      {...props}
      classes={{ root: classes.root, selected: classes.selected }}
      scrollButtons="on"
      variant="scrollable"
      indicatorColor="primary"
      textColor="secondary"
      ScrollButtonComponent={tabsOverflowButtonFactory(props.children)}
    >
      {props.children}
    </MuiTabs>
  );
}

const useStylesSubTab = makeStyles(theme => ({
  root: {
    minHeight  : theme.dimensions.inputHeight,
    whiteSpace: 'nowrap',
    textAlign  : 'left',
    boxShadow : `inset -1px 0px ${theme.palette.divider}`,
    fontSize   : theme.typography.size.xs,
  },
  textColorInherit: {
    color: theme.palette.neutral.contrastText
  },
  selected: {
    backgroundColor: theme.palette.neutral[50],
  },
}));

export function SubTab(props) {
  const classes = useStylesSubTab();
  return (
    <MuiTab
      {...props}
      classes={{
        root            : classes.root,
        textColorInherit: classes.textColorInherit,
        selected        : classes.selected,
      }}
      textColor="inherit"
    >
      {props.children}
    </MuiTab>
  );
}
