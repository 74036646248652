import React from 'react';
import ValidatedDatepicker from './ValidatedDatepicker';

/**
 * Time HH:MM
 * @param {*} props
 */
const TimeHHMMField = props => {
  return <ValidatedDatepicker {...props}
    expression={/^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/gm}
    format="HH:mm"
    showTimeSelect
    showTimeSelectOnly />;
};

export default TimeHHMMField;
