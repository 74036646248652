import React, { useEffect, useState, useRef, forwardRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icon } from '..';
import { Menu, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  contextMenuContainer: {
    borderRadius: theme.dimensions.borderRadiusPanel,
    minWidth    : 160,
    outline     : 0,
    marginLeft  : '-5px',
    marginTop   : '-5px',
    fontSize    : theme.typography.size.s,
  },
  list: {
    minWidth     : 160,
    paddingTop   : 0,
    paddingBottom: 0,
  },
  iconStyle: {
    fontSize: theme.typography.size.l,
    color   : theme.palette.secondary.main,
    margin  : '0 7px 0 0',
  },
  paper: {
    boxShadow: theme.dimensions.shadowDialog,
  },
  tabindex: {
    '&:focus': {
      outline        : 0,
      backgroundColor: theme.palette.action.selected,
    },
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const useStylesSubMenu = makeStyles(theme => ({
  list: {
    minWidth     : 160,
    width        : '100%',
    paddingTop   : 0,
    paddingBottom: 0,
  },
  subMenuIcon: {
    position: 'absolute',
    right   : 5,
    fontSize: theme.typography.size.m,
  },
  iconStyle: {
    fontSize: theme.typography.size.l,
    color   : theme.palette.secondary.main,
    margin  : '0 7px 0 0',
  },
  paper: {
    boxShadow: theme.dimensions.shadowDialog,
    maxHeight: 300,
    overflowY: 'auto',
  },
  tabindex: {
    '&:focus': {
      outline        : 0,
      backgroundColor: theme.palette.action.selected,
    },
  },
  listItem: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  menuContainer: {
    pointerEvents: 'auto',
    maxHeight    : 300,
    overflowY    : 'auto',
    '&:focus'    : {
      outline: 0,
    }
  }
}));

const useStylesSubHeader = makeStyles(theme => ({
  root: {
    opacity             : '1 !important',
    paddingTop          : '8px',
    paddingBottom       : '6px',
    lineHeight          : '1.5',
    paddingLeft         : '10px',
    letterSpacing       : theme.dimensions.letterSpacing,
    textTransform       : 'uppercase',
    borderBottom        : `1px solid ${theme.palette.neutral[400]} !important`,
    fontSize            : theme.typography.size.s,
    color               : theme.palette.text.primary,
    fontWeight          : theme.typography.fontWeightBold,
    backgroundColor     : theme.palette.neutral[200],
    borderTopLeftRadius : theme.dimensions.borderRadiusPanel,
    borderTopRightRadius: theme.dimensions.borderRadiusPanel,
    '&:hover'           : {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const SUBMENU_OPENSTATE = {
  CLOSED : 0,
  CLOSING: 1,
  OPENED : 2,
};

export const triggerContextMenu = (
  id,
  target,
  targetXOffset = 1.0,
  targetYOffset = 1.0,
  collectedData = undefined,
) => {
  const { left, top, width, height } = target?.getBoundingClientRect();

  if (left && top && width && height) {
    const x = left + width * targetXOffset;
    const y = top + height * targetYOffset;

    triggerContextMenuWithPosition(id, x, y, collectedData);
  }
};

export const triggerContextMenuWithPosition = (id, x, y, collectedData = undefined) => {
  const eventContextMenu = new CustomEvent('opencontextmenu', {
    bubbles: true,
    detail : {
      id,
      x,
      y,
      data: collectedData,
    },
  });

  document.dispatchEvent(eventContextMenu);
};

export const ContextMenuNestedTrigger = props => {
  let passProps = {};
  if(props.className) passProps = {...passProps, className: props.className};

  const handleContextMenu = event => {
    event.preventDefault();

    if (!props.disabled) {
      let x = event.clientX || (event.touches && event.touches[0].pageX);
      let y = event.clientY || (event.touches && event.touches[0].pageY);
      const collectedData = props.collect ? props.collect() : undefined;

      triggerContextMenuWithPosition(props.id, x, y, collectedData);
    }
  };

  return <div onContextMenu={handleContextMenu} {...passProps}>{props.children}</div>;
};

export const ContextMenuNested = props => {
  const [context, setContext] = useState(null);
  const classes = useStyles();

  const handleOpenContextMenu = event => {
    if (props.id === event.detail.id) {
      setContext(event.detail);
    }
  };

  const handleKeyNavigation = event => {
    if (event.key === 'Escape') {
      handleClose();
    } else if (event.key === 'Enter') {
      event.stopPropagation();
      event.preventDefault();
    } else if (event.key === 'ArrowLeft') {
      handleClose();
    }
  };

  const handleClose = () => {
    setContext(null);
  };

  useEffect(() => {
    document.addEventListener('opencontextmenu', handleOpenContextMenu);
    return () => {
      document.removeEventListener('opencontextmenu', handleOpenContextMenu);
    };
  }, []);

  useEffect(() => {
    if (context) {
      props.onShow ? props.onShow(context || undefined) : null;
    } else {
      props.onHide ? props.onHide() : null;
    }
  }, [context]);

  return (
    <Menu
      open={!!context}
      component={'nav'}
      anchorReference="anchorPosition"
      anchorPosition={context ? { top: context.y, left: context.x } : undefined}
      className={classes.contextMenuContainer}
      classes={{ list: classes.list, paper: classes.paper }}
      MenuListProps={{
        onMouseLeave: () => handleClose(),
        component   : 'div'
      }}
      onClose={handleClose}
      onKeyDown={handleKeyNavigation}
    >
      {props.children}
    </Menu>
  );
};

export const ContextMenuNestedItem = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <MenuItem component={'div'} ref={ref} {...props} className={classes.listItem}>
      {!!props.icon && <Icon className={classes.iconStyle}>{props.icon}</Icon>}
      {!!props.additionalIcon && <Icon className={classes.iconStyle}>{props.additionalIcon}</Icon>}
      {props.children}
    </MenuItem>
  );
});

export const ContextMenuNestedSubheader = forwardRef((props, ref) => {
  const classes = useStylesSubHeader();
  return (
    <MenuItem
      component={'div'}
      ref={ref}
      {...props}
      disabled={true}
      classes={{ root: classes.root }}
    >
      {props.children}
    </MenuItem>
  );
});

export const ContextSubMenuNested = forwardRef((props, _ref) => {
  const [open, setOpen] = useState(SUBMENU_OPENSTATE.CLOSED);
  const menuItemRef = useRef(null);
  const containerRef = useRef(null);
  const menuContainerRef = useRef(null);
  const classes = useStylesSubMenu();
  let delayMouseEnter = undefined;
  let passProps = {};

  const { children, tabIndex, label, ContainerProps = {} } = props;

  if(props['data-element']) {
    passProps = {
      ...passProps,
      ['data-element']: props['data-element']
    };
  }

  useEffect(() => {
    return () => {
      if(delayMouseEnter) clearTimeout(delayMouseEnter);
    };
  }, []);

  const handleMouseEnter = event => {
    if (!props.disabled) {
      delayMouseEnter = setTimeout(() => setOpen(SUBMENU_OPENSTATE.OPENED), 500);
    }
    if (ContainerProps?.onMouseEnter) {
      ContainerProps.onMouseEnter(event);
    }
  };

  const handleMouseLeave = event => {
    if(delayMouseEnter) clearTimeout(delayMouseEnter);
    setOpen(SUBMENU_OPENSTATE.CLOSED);
    if (ContainerProps?.onMouseLeave) {
      ContainerProps.onMouseLeave(event);
    }
  };

  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument?.activeElement;
    for (const child of menuContainerRef.current?.children ?? []) {
      if (child === active) {
        return true;
      }
    }
    return false;
  };

  const handleBlur = _event => {
    if (open === SUBMENU_OPENSTATE.CLOSING) setOpen(SUBMENU_OPENSTATE.CLOSED);
  };

  const handleFocus = event => {
    if (event.target === containerRef.current) {
      if (!props.disabled) {
        if (open !== SUBMENU_OPENSTATE.CLOSING) {
          setOpen(SUBMENU_OPENSTATE.OPENED);
        } else {
          setOpen(SUBMENU_OPENSTATE.CLOSED);
        }
      }
    }
    if (ContainerProps?.onFocus) {
      ContainerProps.onFocus(event);
    }
  };

  const handleKeyDown = event => {
    if (event.key === 'Escape') {
      return;
    }

    if (isSubmenuFocused()) {
      event.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument?.activeElement;

    if (event.key === 'ArrowLeft' && isSubmenuFocused()) {
      containerRef.current?.focus();
      setOpen(SUBMENU_OPENSTATE.CLOSED);
    }

    if(event.key === 'Enter') {
      props.onClick ? props.onClick(event) : null;
      event.stopPropagation();
    }

    if (
      event.key === 'ArrowRight' &&
      event.target === containerRef.current &&
      event.target === active &&
      !props.disabled
    ) {
      if (open === SUBMENU_OPENSTATE.OPENED) {
        const firstChild = menuContainerRef.current?.children[0];
        firstChild?.focus();
      } else {
        setOpen(SUBMENU_OPENSTATE.OPENED);
      }
    }

    if (
      (event.key === 'ArrowDown' || event.key === 'ArrowUp') &&
      event.target === containerRef.current &&
      event.target === active
    ) {
      if (open === SUBMENU_OPENSTATE.OPENED) {
        event.stopPropagation();
        event.preventDefault();
        setOpen(SUBMENU_OPENSTATE.CLOSING);
      }
    }
  };

  return (
    <div
      ref={containerRef}
      onFocus={handleFocus}
      tabIndex={tabIndex || 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      onBlur={handleBlur}
      className={classes.tabindex}
    >
      <MenuItem
        ref={menuItemRef}
        component={'div'}
        disabled={props.disabled || false}
        onClick={() => (props.onClick ? props.onClick() : null)}
        className={classes.listItem}
        {...passProps}
      >
        {!!props.icon && <Icon className={classes.iconStyle}>{props.icon}</Icon>}
        {label}
        <Icon className={classes.subMenuIcon}>chevron_right</Icon>
      </MenuItem>
      <Menu
        classes={{ list: classes.list, paper: classes.paper }}
        style={{ pointerEvents: 'none' }}
        component={'nav'}
        anchorEl={menuItemRef.current}
        anchorOrigin={{
          vertical  : 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical  : 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          component: 'div'
        }}
        open={open === SUBMENU_OPENSTATE.OPENED}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => setOpen(SUBMENU_OPENSTATE.CLOSED)}
        disabled={props.disabled || false}
      >
        <div ref={menuContainerRef} className={classes.menuContainer}>
          {children}
        </div>
      </Menu>
    </div>
  );
});
