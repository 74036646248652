import React from 'react';
import { InputAdornment, TextField } from '@material-ui/core';
import IconButton from './IconButton';
import Icon from './Icon';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor                                     : theme.palette.background.paper,
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  input: {
    borderRadius        : theme.dimensions.borderRadiusInput,
    backgroundColor     : theme.palette.background.paper,
    '&:-webkit-autofill': {
      borderRadius: 0
    }
  },
  inputMarginDense: {
    paddingTop     : '8.5px',
    paddingBottom: '8.5px',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    }
  },
  focused: {
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  disabled   : {},
  error      : {},
  marginDense: {
    transform: 'translate(14px, 10px) scale(1) !important'
  },
  shrink: {
    transform: 'translate(14px, -6px) scale(0.75) !important'
  },
  labelDisabled: {
    color: `${theme.palette.grey[800]} !important`
  }
}));

const PasswordInputField = props => {
  const classes = useStyles();
  const [values, setValues] = React.useState({
    password    : '',
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <TextField
      {...props}
      type={values.showPassword ? 'text' : 'password'}
      onChange={props.onChange}
      name={props.name}
      label={props.name}
      placeholder={props.placeholder}
      color="secondary"
      classes={{ ...props.classes }}
      InputLabelProps={{
        classes: {
          shrink     : classes.shrink,
          marginDense: classes.marginDense,
          disabled   : classes.labelDisabled
        }
      }}
      InputProps={{
        classes: {
          root            : classes.root,
          input           : classes.input,
          inputMarginDense: classes.inputMarginDense,
          notchedOutline  : classes.notchedOutline,
          focused         : classes.focused,
          disabled        : classes.disabled,
          error           : classes.error
        },
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              size="small"
              aria-label={('Passwort_Sichtbarkeit_umschalten')}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {values.showPassword ? <Icon aria-label={('Passwort_ausblenden')}>visibility_off</Icon> : <Icon aria-label={('Passwort_einblenden')}>visibility</Icon>}
            </IconButton>
          </InputAdornment>
        ),
      }}
      margin="dense"
      variant="outlined"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
    ></TextField>
  );
};

export default PasswordInputField;
