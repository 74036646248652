import React from 'react';
import { DialogTitle as MuiDialogTitle, Typography } from '@material-ui/core';
import { withStyles } from '../../styles';
import IconButton from '../IconButton';
import Icon from '../Icon';

const styles = muiTheme => ({
  root: {
    margin         : 0,
    padding        : muiTheme.spacing(2, 3),
    color          : muiTheme.palette.text.primary,
    backgroundColor: muiTheme.palette.neutral[100]
  },
  closeButton: {
    position: 'absolute !important',
    right   : muiTheme.spacing(2),
    top     : muiTheme.spacing(1),
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Icon>close</Icon>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export default DialogTitle;
