import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Numeric Field
 * @see https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 * @param {*} props
 */
const NumericField = props => {
  return <ValidatedField {...props} expression={/^[0-9]*$/gm} />;
};

export default NumericField;
