import { DialogActions as MuiDialogActions } from '@material-ui/core';
import { withStyles } from '../../styles';

const DialogActions = withStyles(muiTheme => ({
  root: {
    margin                 : 0,
    padding                : muiTheme.spacing(2),
    '& button:nth-child(2)': {
      marginLeft: '8px !important',
    },
  },
}))(MuiDialogActions);

export default DialogActions;
