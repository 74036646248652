import React from 'react';
import { TableRow as MuiTableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    height             : muiTheme.dimensions.tableRowHeight,
    transition         : muiTheme.transitions.create(['background-color'], { duration: muiTheme.transitions.duration.shortest}),
    '&:nth-child(even)': {
      backgroundColor:    muiTheme.palette.neutral[50],
    },
    '&:hover': {
      backgroundColor: muiTheme.palette.action.hover
    },
    '&:focus': {
      backgroundColor: muiTheme.palette.action.selected
    }
  },
}));

const TableRow = props => {
  const classes = useStyles();
  return (
    <MuiTableRow classes={{ root: classes.root }} {...props}>
      {props.children}
    </MuiTableRow>
  );
};

export default TableRow;
