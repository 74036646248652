import React from 'react';
import { Badge as MuiBadge } from '@material-ui/core';

const Badge = props => {
  return (
    <MuiBadge
      overlap="circular"
      anchorOrigin={{
        vertical  : 'bottom',
        horizontal: 'right',
      }}
      {...props}
    >
      {props.children}
    </MuiBadge>
  );
};

export default Badge;
