import React from 'react';
import { default as ReactSelect } from 'react-select';
import styled, { createGlobalStyle } from 'styled-components';
import { withTheme, useTheme } from '@material-ui/core';
// import { useTranslate } from '@pcweb/hooks';

const GlobalStyles = createGlobalStyle`
    .pcweb-select__menu {
        top: 0 !important;
        bottom: auto !important;
        z-index: 999999;
        color: ${(props) => props.theme.palette.primary.text} !important;
        background-color: ${(props) => props.theme.palette.common.white};
        font-size: ${(props) => props.theme.typography.size.m};
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: ${(props) =>
  props.theme.dimensions.borderRadiusInput} !important;
        border-bottom-left-radius: ${(props) =>
    props.theme.dimensions.borderRadiusInput} !important;
        max-height: 300px;
        box-shadow: ${(props) =>
      props.theme.dimensions.shadowDialog} !important;
    }
    .pcweb-select__menu-list {
      background-color: ${(props) => props.theme.palette.common.white};
      max-height: 300px;
    }

    @media screen and (max-height: 400px) {
      .pcweb-select__menu, .pcweb-select__menu-list {
        max-height: 100px;
      }
    }

    .pcweb-select__option--is-focused {
        background-color: ${(props) =>
        props.theme.palette.action.hover} !important;
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    .pcweb-select__option--is-selected,
    .pcweb-select__option--is-focused.pcweb-select__option--is-selected {
        background-color: ${(props) =>
          props.theme.palette.action.selected} !important;
        color: ${(props) => props.theme.palette.text.primary} !important;
    }

    .pcweb-select__option {
      padding: 8.5px 0 8.5px 5px;
      line-height: 1;
      background-color: ${(props) => props.theme.palette.common.white};
    }
`;

const GlobalStyleAdditions = withTheme(
  React.memo((props) => <GlobalStyles {...props} />)
);

const StyledSelectWithoutTheme = styled(ReactSelect)`
  width: 100%;
  margin-bottom: 0.4rem;
  transition: ${(props) => props.theme.transitions.create('opacity')};
  background-color: ${(props) => props.theme.palette.background.paper};
  border-radius: 4px;

  &.pcweb-select--is-disabled {
    opacity: 0.5;
  }

  .pcweb-select__control {
    border-radius: ${(props) => props.theme.dimensions.borderRadiusInput};
    color: ${(props) => props.theme.palette.text.primary};
    border-color: ${(props) => props.theme.palette.secondary.main};
    font-size: ${(props) => props.theme.typography.size.m};
    min-height: ${(props) => props.theme.dimensions.inputHeight};
    background-color: ${(props) => props.theme.palette.background.paper};
    padding-left: 5px;
  }

  .pcweb-select__control:hover {
    cursor: pointer;
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  .pcweb-select__control:hover .pcweb-select__indicator {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .pcweb-select__control:focus {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  .pcweb-select__input > input {
    opacity: 1;
  }

  .pcweb-select__single-value {
    overflow: inherit;
  }

  .pcweb-select__control--is-focused,
  .pcweb-select__control--is-focused:hover {
    color: ${(props) => props.theme.palette.text.primary};
    outline: 0;
    border-color: ${(props) => props.theme.palette.primary.main};
    box-shadow: none;
    background-color: ${(props) => props.theme.palette.background.paper};
  }

  .pcweb-select__control--is-focused .pcweb-select__indicator {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .pcweb-select__indicator {
    cursor: pointer;
    color: ${(props) => props.theme.palette.secondary.main};
    padding: 0 0.5rem;
  }

  .pcweb-select__indicator:hover {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .pcweb-select__indicator svg {
    height: 15px;
    width: 15px;
    margin-top: 2px;
  }

  .pcweb-select__indicator-separator {
    display: none;
  }

  .pcweb-select__value-container {
    padding: 0;
    overflow: inherit;
    background-color: ${(props) => props.theme.palette.background.paper};
  }

  // .pcweb-select__value-container--has-value > div {

  // }

  .pcweb-select__input > input {
    opacity: 1 !important;
  }

  .pcweb-select__placeholder {
    color: ${(props) => props.theme.palette.text.disabled};
    top: 55%;
  }
`;
const StyledSelect = withTheme(StyledSelectWithoutTheme);

const SelectComponentOld = (props) => {
  // const translate = useTranslate();
  const { options, valueName, displayName, selected, disabled } = props;
  const theme = useTheme();

  let optionList = options.map((option) => ({
      value: option[valueName],
      label: option[displayName],
    })),
    value;

  if (selected) {
    value = optionList.filter((option) => option.value === selected)[0];
  }

  // Remove options from the props before passing them on.
  let { options: oldOptions, ...otherProps } = props;

  return (
    <React.Fragment>
      <GlobalStyleAdditions />
      <StyledSelect
        options={optionList}
        value={value}
        isDisabled={disabled}
        data-element={props['data-element']}
        classNamePrefix="pcweb-select"
        menuPlacement="bottom"
        menuPosition="absolute"
        menuPortalTarget={document.body}
        // menuIsOpen
        placeholder="Option wählen"
        // placeholder={translate('Option wählen')}
        noOptionsMessage={() => null}
        styles={{
          // We need to set these styles here because a) the portal cannot be styled through the styled component
          // and b) the portal itself does not get applied the pcweb-select class, hence we cannot style it through
          // createGlobalStyle. Setting the z-index here is all we really need though.
          menuPortal: (base) =>
            Object.assign(base, {
              zIndex: 9999,
            }),
        }}
        {...otherProps}
      />
      {props.helperText && (
        <p style={{ color: theme.palette.utility.red.main }}>
          {props.helperText}
        </p>
      )}
    </React.Fragment>
  );
};
export default SelectComponentOld;
