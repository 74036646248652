import React from 'react';
import ValidatedField from './ValidatedField';
/**
 * Decimal Number with 3 after comma digits
 * @param {*} props
 */
const Decimal3Field = props => {
  return <ValidatedField {...props} expression={/^[0-9]*,[0-9][0-9][0-9]$/gm} />;
};

export default Decimal3Field;
