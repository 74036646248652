import React from 'react';
import { Table as MuiTable } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    borderCollapse       : 'collapse',
    '& tr:first-child th': {
      borderTop: 0,
    },
    '& tr:lst-child td': {
      borderBottom: 0,
    },
    '& tr td:first-child': {
      borderLeft: 0,
    },
    '& tr th:first-child': {
      borderLeft: 0,
    },
    '& tr td:last-child': {
      borderRight: 0,
    },
    '& tr th:last-child': {
      borderRight: 0,
    },
  },
});

const Table = props => {
  const classes = useStyles();
  return (
    <MuiTable size="small" classes={{ root: classes.root }} {...props}>
      {props.children}
    </MuiTable>
  );
};

export default Table;
