import React from 'react';
import ValidatedDatepicker from './ValidatedDatepicker';

/**
 * Date dd.mm.yyyy hh:mm:ss
 * @param {*} props
 */
const Date_DDMMYYYY_HHMMSS_Field = props => {
  return (
    <ValidatedDatepicker
      {...props}
      expression={/^(([1-2][0-9])|([0-2][1-9])|(3)[0-1])(\.)(((0)[1-9])|((1)[0-2]))(\.)\d{4} ([0-1][0-9]|[2][0-3]):([0-5][0-9]:[0-5][0-9])$/gm}
      format="DD.MM.yyyy HH:mm:ss"
      showTimeSelect
      showTimeSelectOnly={false}
    />
  );
};

export default Date_DDMMYYYY_HHMMSS_Field;
