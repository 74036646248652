import React, { forwardRef } from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    '& .red': {
      color: muiTheme.palette.utility.red.main,
    },
    '& .green': {
      color: muiTheme.palette.utility.green.main,
    },
  },
}));

const IconButton = forwardRef((props, ref) => {
  const classes = useStyles();
  return (
    <MuiIconButton
      {...props}
      classes={{
        root: classes.root,
        ...props.classes,
      }}
      color="primary"
      ref={ref}
    >
      {props.children}
    </MuiIconButton>
  );
});

export default IconButton;
