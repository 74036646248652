import React from 'react';
import { TableCell as MuiTableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    fontSize   : muiTheme.typography.size.s,
    borderColor: muiTheme.palette.divider,
    whiteSpace : 'nowrap',
    '& > i'    : {
      verticalAlign: 'middle',
      color        : muiTheme.palette.text.secondary,
      '& .green'   : {
        color: muiTheme.palette.utility.green.main,
      },
      '& .red': {
        color: muiTheme.palette.utility.red.main,
      },
    },
    '& span': {
      '& > i': {
        verticalAlign: 'middle',
      },
    },
  },
  head: {
    color          : muiTheme.palette.text.primary,
    backgroundColor: muiTheme.palette.neutral[200],
    position       : 'sticky',
    top            : 0,
    zIndex         : '10',
    fontWeight     : '500',
    textOverflow   : 'ellipsis',
    whiteSpace     : 'nowrap',
  },
  sizeSmall: {
    padding       : '0 5px',
    '&:last-child': {
      paddingRight: 0,
    },
  },
}));

const TableCell = props => {
  const classes = useStyles();
  return (
    <MuiTableCell
      classes={{
        root     : classes.root,
        head     : classes.head,
        sizeSmall: classes.sizeSmall,
      }}
      {...props}
    >
      {props.children}
    </MuiTableCell>
  );
};

export default TableCell;
