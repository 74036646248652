import React from 'react';
import { Avatar as MuiAvatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize     : theme.typography.size.m,
    fontWeight   : theme.typography.fontWeightMedium,
    textTransform: 'uppercase',
  },
  colorDefault: {
    background: theme.palette.blue.dark,
    color     : theme.palette.common.white,
  },
}));

const Avatar = props => {
  const classes = useStyles();

  return (
    <MuiAvatar
      classes={{
        root        : classes.root,
        colorDefault: classes.colorDefault,
      }}
      {...props}
    >
      {props.children}
    </MuiAvatar>
  );
};

export default Avatar;
