import React from 'react';
import Link from '@material-ui/core/Link';

/**
 * Link wrapper for anything which need a link
 * @param {*} props
 */
const LinkWrap = props => {
  if (props.href?.length > 0) {
    return (
      <Link {...props}>
        {props.children}
      </Link>
    );
  }

  return <>{props.children}</>;
};

export default LinkWrap;