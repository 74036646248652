import {
  // Drawer,
  Popover,
} from '@material-ui/core';
import React from 'react';

// const renderDrawer = props => {
//   let { drawerPaperProps, ...rest } = props;

//   delete rest['anchorEl'];
//   delete rest['anchorOrigin'];
//   delete rest['transformOrigin'];
//   delete rest['onEntered'];
//   delete rest['popoverPaperProps'];
//   return (
//     <Drawer anchor="bottom" PaperProps={drawerPaperProps} {...rest}>
//       {props.children}
//     </Drawer>
//   );
// };

const renderPopover = props => {
  let { popoverPaperProps, classes, ...rest } = props;
  delete rest['drawerPaperProps'];
  const shadowClass = ' drop-shadow-override';
  classes = !classes ? { paper: shadowClass } : { paper: classes.paper + shadowClass };

  return (
    <Popover marginThreshold={0} classes={classes} PaperProps={popoverPaperProps} {...rest}>
      {props.children}
    </Popover>
  );
};

export const Popout = props => {
  // let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

  // if (viewportWidth <= 640) {
  //   return renderDrawer(props);
  // }

  return renderPopover(props);
};
