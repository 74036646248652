import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Phone Number
 */
const PhoneNumberField = props => {
  return <ValidatedField {...props} expression={/^(\+\d{2})?\d{6}\d*$/gm} />;
};

export default PhoneNumberField;
