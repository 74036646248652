import React from 'react';
import {
  List as MuiList,
  ListItem as MuiListItem,
  ListItemIcon as MuiListItemIcon,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  '@keyframes fadeInBottom': {
    from: { opacity: 0, transform: 'translateY(50%)' },
    to  : { opacity: 1 },
  },
  list: {
    marginLeft: 45,
  },
  listItem: {
    alignItems       : 'center',
    padding          : '3px 0',
    animationName    : '$fadeInBottom',
    animationDuration: '100ms',
    animationFillMode: 'both',
    '& i'            : {},
  },
  listItemText: {
    margin    : 0,
    '& > span': {
      color     : theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightNormal,
    },
  },
  listItemTextBold: {
    marginTop : 0,
    '& > span': {
      fontSize  : theme.typography.size.m,
      color     : theme.palette.text.primary,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  listItemTextEntity: {
    margin    : '0 5px 0 0',
    flex      : 'initial',
    '& > span': {
      fontSize     : theme.typography.size.xs,
      color        : theme.palette.text.primary,
      fontWeight   : theme.typography.fontWeightMedium,
      textTransform: 'uppercase',
    },
  },
  listItemIcon: {
    minWidth: 18,
    '& i'   : {
      fontSize: 12,
    },
  },
}));

export const IconList = props => {
  const classes = useStyles();
  return (
    <MuiList disablePadding className={classes.list} {...props}>
      {props.children}
    </MuiList>
  );
};

export const IconListItem = props => {
  const classes = useStyles();
  return (
    <MuiListItem className={classes.listItem} {...props} component="li" disableGutters>
      {props.children}
    </MuiListItem>
  );
};

export const IconListItemIcon = props => {
  const classes = useStyles();
  return (
    <MuiListItemIcon {...props} className={classes.listItemIcon}>
      {props.children}
    </MuiListItemIcon>
  );
};

export const IconListItemText = props => {
  const classes = useStyles();
  return (
    <MuiListItemText className={classes.listItemText} {...props}>
      {props.children}
    </MuiListItemText>
  );
};
export const IconListItemTextBold = props => {
  const classes = useStyles();
  return (
    <MuiListItemText className={classes.listItemTextBold} {...props}>
      {props.children}
    </MuiListItemText>
  );
};
export const IconListItemTextEntity = props => {
  const classes = useStyles();
  return (
    <MuiListItemText className={classes.listItemTextEntity} {...props}>
      {props.children}
    </MuiListItemText>
  );
};
