import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height       : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    '&:hover'    : {
      backgroundColor: theme.palette.primary.dark,
    },
    '& i': {
      marginRight: '5px',
      color      : theme.palette.common.white,
    },
  },
  rootNoHover: {
    height       : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    '& i'        : {
      marginRight: '5px',
      color      : theme.palette.common.white,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[200] + '!important',
    color          : theme.palette.text.disabled  + '!important',
    '& i'          : {
      color: theme.palette.text.disabled  + '!important',
    },
  },
  flex: {
    display   : 'flex',
    alignItems: 'center',

  },
  buttonProgress: {
    color      : theme.palette.common.white,
    marginRight: theme.spacing(1)
  },
}));

const PrimaryButton = ({ loading, ...props}) => {
  const classes = useStyles();

  const getRootClass = () => {
    return (props?.color && !['primary','secondary'].includes(props.color) ?
      classes.rootNoHover : classes.root);
  };

  return (
    <Button classes={{ root: getRootClass(), disabled: classes.disabled }}
      variant="contained" color="primary" disableElevation {...props}>
      <div className={classes.flex}>
        {loading && <CircularProgress size={16} className={classes.buttonProgress} />}
        <span className={classes.flex}>{props.children}</span>
      </div>
    </Button>
  );
};

export default PrimaryButton;
