import React from 'react';
import { TableHead as MuiTableHead } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    backgroundColor: muiTheme.palette.neutral[200],
  },
}));

const TableHead = props => {
  const classes = useStyles();
  return (
    <MuiTableHead classes={{ root: classes.root }} {...props}>
      {props.children}
    </MuiTableHead>
  );
};

export default TableHead;
