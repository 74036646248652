import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Number or Decimal with 2 digits after comma, with Thousand separator
 * @param {*} props
 */
const CurrencyEuroWithSeperatorField = props => {
  return <ValidatedField {...props} expression={/^[0-9]{1,3}(\.[0-9]{3})*(,[0-9]{2})?$/g} />;
};

export default CurrencyEuroWithSeperatorField;
