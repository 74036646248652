import React from 'react';
import { Chip as MuiChip } from '@material-ui/core';

const Chip = props => {
  return (
    <MuiChip {...props}>
      {props.children}
    </MuiChip>
  );
};

export default Chip;
