import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * PLZ
 * @see https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 * @param {*} props
 */
const PostCodeField = props => {
  return <ValidatedField inputProps={{ maxLength: 6 }} {...props} expression={/^[0-9]{4,6}$/gm} />;
};

export default PostCodeField;
