import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Number or Decimal with 2 digits after comma
 * @param {*} props
 */
const CurrencyEuroField = props => {
  return <ValidatedField {...props} expression={/^\d+(,\d{2})?$/gm} />;
};

export default CurrencyEuroField;
