import React from 'react';
import { AppBar as MuiAppBar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  root: {
    boxShadow           : 'none',
    borderTopLeftRadius : muiTheme.dimensions.borderRadiusPanel,
    borderTopRightRadius: muiTheme.dimensions.borderRadiusPanel,
  },
  colorSecondary: {
    backgroundColor: muiTheme.palette.blue[800]
  }
}));

const AppBar = props => {
  const classes = useStyles();
  return (
    <MuiAppBar position="static" color="default" classes={{root: classes.root, colorSecondary: classes.colorSecondary}} {...props}>
      {props.children}
    </MuiAppBar>
  );
};

export default AppBar;
