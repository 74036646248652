import React from 'react';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  marginDense: {
    paddingTop     : '8.5px !important',
    paddingBottom: '8.5px !important',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    }
  },
  focused: {
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  multiline: {
    borderRadius        : theme.dimensions.borderRadiusInput,
    transition          : theme.transitions.create('border-color'),
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    '& textarea:nth-child(1), & fieldset': {
      minHeight: '150px',
    }
  }
}));

const TextArea = props => {
  const classes = useStyles();
  return (
    <TextField
      {...props}
      type="text"
      onChange={props.onChange}
      label={props.name}
      value={props.value}
      placeholder={props.name}
      color="secondary"
      classes={{ ...props.classes }}
      multiline
      minRows={10}
      margin="dense"
      variant="outlined"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      InputProps={{
        className: classes.input,
        classes  : {
          root          : classes.root,
          multiline     : classes.multiline,
          marginDense   : classes.marginDense,
          notchedOutline: classes.notchedOutline,
          focused       : classes.focused,
        },
      }}
      InputLabelProps={{
        shrink: true,
      }}
    ></TextField>
  );
};

export default TextArea;
