import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Unicode E-Mail Address
 * @see  https://4com.kanbanize.com/ctrl_board/5/cards/36494/details/
 * @see https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
 * @param {*} props
 */
const EMailField = props => {
  return (
    <ValidatedField
      {...props}
      expression={
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/gi
      }
    />
  );
};

export default EMailField;
