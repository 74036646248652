import React, { useState, useEffect, useRef } from 'react';

import TextInputField from '../TextInputField';
import { mergeRefs } from '../../api/util/mergeRefs';

const isValueEmpty = (props) => {
  return !props.value || props.value.length === 0;
};

const ValidatedField = React.forwardRef(({ validationIcon = null, ...props }, ref) => {
  const multilineExpression = new RegExp(props.expression, 'gm');
  const handleParentChange = props.onChange;

  const [error, setError] = useState(props.error);
  const elementRef = useRef(null);

  const bindForceChangeEvent = () => {
    elementRef?.current?.addEventListener('forceChange', handleChange);
  };

  const bindChangeOnceEvent = () => {
    elementRef?.current?.addEventListener('change', handleChange, { once: true });
  };

  const doesNeedtoValidateInitialValue = () => {
    return (!!elementRef?.current && !isValueEmpty(props) && !!props.required);
  };

  const dispatchInitialChangeEvent = () => {
    elementRef?.current?.dispatchEvent(new Event('change', { bubbles: true }));
  };

  useEffect(() => {
    if (!props.error && isValueEmpty(props)) setError(false);
  }, [props.error]);

  useEffect(() => {
    bindForceChangeEvent();

    if (doesNeedtoValidateInitialValue()) {
      bindChangeOnceEvent();
      dispatchInitialChangeEvent();
    }
  }, [elementRef?.current]);

  const handleChange = (e) => {
    const inputValue = e.target.value;
    let hasError;
    if (props.required) {
      hasError = !inputValue.match(multilineExpression);
    } else {
      hasError =
        inputValue.length > 0 &&
        !inputValue.match(multilineExpression);
    }
    if (handleParentChange) {
      handleParentChange(e, hasError);
    }

    setError(hasError);
  };

  return (
    <TextInputField
      {...props}
      onChange={(e) => handleChange(e)}
      ref={mergeRefs([ref, elementRef])}
      type="text"
      error={error}
      validationIcon={validationIcon}
    ></TextInputField>
  );
});

export default ValidatedField;
