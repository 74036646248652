import React from 'react';
import ValidatedField from './ValidatedField';

/**
 * Simple Validation for Username Field
 * The username should at least contain string@string.string
 * https://4com.kanbanize.com/ctrl_board/5/cards/35986/comments/
 * @param {*} props
 */
const UsernameField = props => {
  return (
    <ValidatedField
      {...props}
      expression={
        /^(.+)@(.+)\.(.+)$/gi
      }
    />
  );
};

export default UsernameField;
