import React from 'react';
import ValidatedDatepicker from './ValidatedDatepicker';

/**
 * Date dd.mm.yyyy
 * @param {*} props
 */
const Date_DDMMYYYY_Field = props => {
  return (
    <ValidatedDatepicker
      {...props}
      expression={/^(([1-2][0-9])|([0-2][1-9])|(3)[0-1])(\.)(((0)[1-9])|((1)[0-2]))(\.)\d{4}$/gm}
      format="DD.MM.yyyy"
      showTimeSelect={false}
      showTimeSelectOnly={false}
    />
  );
};

export default Date_DDMMYYYY_Field;
