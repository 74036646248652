import React from 'react';
import ValidatedDatepicker from './ValidatedDatepicker';

/**
 * Date yyyy-mm-dd
 * @param {*} props
 */
const Date_YYYYMMDD_Field = props => {
  return (
    <ValidatedDatepicker
      {...props}
      expression={/^\d{4}(-)(((0)[1-9])|((1)[0-2]))(-)(([1-2][0-9])|([0-2][1-9])|(3)[0-1])$/gm}
      format="YYYY-MM-DD"
      showTimeSelect={false}
      showTimeSelectOnly={false}
    />
  );
};

export default Date_YYYYMMDD_Field;
