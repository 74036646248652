import React from 'react';
import {
  ListItemIcon as MuiListItemIcon,
  List as MuiList,
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStylesList = makeStyles(() => ({
  root: {
    '& li:last-child': {
      borderBottomColor: 'transparent',
    },
  },
}));

export const List = props => {
  const classes = useStylesList();
  return (
    <MuiList margin="dense" component="nav" className={classes.root} {...props}>
      {props.children}
    </MuiList>
  );
};

const useStylesListItem = makeStyles(theme => ({
  root: {
    paddingLeft : theme.spacing(1.5),
    lineHeight  : `${theme.spacing(4)}px`,
    height      : theme.spacing(4),
    fontSize    : theme.typography.size.m,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:hover'   : {
      backgroundColor: theme.palette.action.hover,
    },
  },
  selected: {
    backgroundColor: theme.palette.action.selected,
  },
}));

export const ListItem = React.forwardRef((props, ref) => {
  const classes = useStylesListItem();
  return (
    <MuiListItem
      ref={ref}
      margin="dense"
      classes={{ root: classes.root, selected: classes.selected }}
      button
      {...props}
      component={props.component ? props.component : 'li'}
    >
      {props.children}
    </MuiListItem>
  );
});

const useStylesListItemIcon = makeStyles(theme => ({
  root: {
    minWidth: theme.spacing(4),
    '& i'   : {
      fontSize   : 18,
      marginRight: 0,
      color      : theme.palette.secondary.main,
    },
  },
}));

export const ListItemIcon = props => {
  const classes = useStylesListItemIcon();
  return (
    <MuiListItemIcon {...props} className={classes.root}>
      <i className="material-icons-4com" role="img">{props.children}</i>
    </MuiListItemIcon>
  );
};

const useStylesListItemText = makeStyles(theme => ({
  primary: {
    letterSpacing: '.6px',
    fontWeight   : theme.typography.fontWeightMedium,
    maxWidth     : 300,
    whiteSpace   : 'nowrap',
    textOverflow : 'ellipsis',
    overflow     : 'hidden',
  },
}));

export const ListItemText = props => {
  const classes = useStylesListItemText();
  return (
    <MuiListItemText className={classes.primary} disableTypography {...props}>
      {props.children}
    </MuiListItemText>
  );
};

const useStylesTruncatedText = makeStyles(() => ({
  root: {
    overflow    : 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace  : 'nowrap',
    maxWidth    : 300,
  },
}));

export const TruncatedText = props => {
  const classes = useStylesTruncatedText();
  return <div className={classes.root}>{props.children}</div>;
};
