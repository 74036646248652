import React from 'react';
import { Paper as MuiPaper } from '@material-ui/core';
import { makeStyles } from '../styles';

const useStyles = makeStyles((theme) => ({
  // root: {}
  elevation1: {
    boxShadow: theme.dimensions.shadowPanel
  }
}));


const Paper = props => {
  const classes = useStyles();
  return (
    <MuiPaper
      {...props}
      elevation={1}
      classes={{ root: classes.root, elevation1: classes.elevation1 }}
    ></MuiPaper>
  );
};

export default Paper;
