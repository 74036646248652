import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    height       : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    '&:disabled' : {
      opacity: '.5',
    },
    '& i': {
      marginRight: '5px',
      color      : theme.palette.common.white,
    },
  },
  containedPrimary: {
    backgroundColor: theme.palette.utility.red.main,
    '&:hover'      : {
      backgroundColor: theme.palette.utility.red.dark,
    },
  },
}));

const PrimaryDangerButton = props => {
  const classes = useStyles();
  return (
    <Button
      {...props}
      variant="contained"
      color="primary"
      disableElevation
      classes={{
        root              : classes.root,
        containedPrimary: classes.containedPrimary,
      }}
    >
      {props.children}
    </Button>
  );
};

export default PrimaryDangerButton;
