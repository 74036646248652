import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import { Icon, Badge } from '..';

const useStyles = makeStyles((muiTheme) => ({
  iconStyle: {
    userSelect  : 'none',
    borderRadius: '50%',
    padding     : '2px',
    border      : `2px solid ${muiTheme.palette.common.white}`,
    '&.grey'    : {
      backgroundColor: muiTheme.palette.text.disabled,
      '& > i'        : {
        color: muiTheme.palette.common.black,
      },
    },
    '&.green': {
      backgroundColor: muiTheme.palette.status.free.main,
      '& > i'        : {
        color: muiTheme.palette.status.free.contrastText.main,
      },
    },
    '&.red': {
      backgroundColor: muiTheme.palette.status.busy.main,
      '& > i'        : {
        color: muiTheme.palette.status.busy.contrastText.main,
      },
    },
    '&.blue': {
      backgroundColor: muiTheme.palette.status.break.main,
      '& > i'        : {
        color: muiTheme.palette.status.break.contrastText.main,
      },
    },
    '&.orange': {
      backgroundColor: muiTheme.palette.status.reserved.main,
      '& > i'        : {
        color: muiTheme.palette.status.reserved.contrastText.main,
      },
    },
    '&.yellow': {
      backgroundColor: muiTheme.palette.status.refinish.main,
      '& > i'        : {
        color: muiTheme.palette.status.refinish.contrastText.main,
      },
    },
    '& > i': {
      fontSize: '12px',
    },
  },
}));

const statusIcon = (statusName, classes) => {
  const statusMap = {
    ['AutoLoggedOff']: (
      <div className={clsx(classes.iconStyle, 'grey')}>
        <Icon aria-label="Auto Logged off">clear</Icon>
      </div>
    ),
    ['Abgemeldet']: (
      <div className={clsx(classes.iconStyle, 'grey')}>
        <Icon aria-label="Logged off">clear</Icon>
      </div>
    ),
    ['Pause']: (
      <div className={clsx(classes.iconStyle, 'blue')}>
        <Icon aria-label="Pause">status_pause</Icon>
      </div>
    ),
    ['Bereit']: (
      <div className={clsx(classes.iconStyle, 'green')}>
        <Icon aria-label="Ready">check</Icon>
      </div>
    ),
    ['Reserviert']: (
      <div className={clsx(classes.iconStyle, 'orange')}>
        <Icon aria-label="Reserved">flag</Icon>
      </div>
    ),
    ['MessageSent']: (
      <div className={clsx(classes.iconStyle, 'orange')}>
        <Icon aria-label="Message sent">flag</Icon>
      </div>
    ),
    ['In Anwahl']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Dialing">call_received</Icon>
      </div>
    ),
    ['Im Gespräch']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Talking">call_received</Icon>
      </div>
    ),
    ['Konferenz']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Conference">call_received</Icon>
      </div>
    ),
    ['BusyUms']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Busy UMS">call_received</Icon>
      </div>
    ),
    ['Nachbearbeitung']: (
      <div className={clsx(classes.iconStyle, 'yellow')}>
        <Icon aria-label="Wrap-up">status_edit</Icon>
      </div>
    ),
    ['Codierung / Nachbearbeitung']: (
      <div className={clsx(classes.iconStyle, 'yellow')}>
        <Icon aria-label="Classification Wrap-up">status_edit</Icon>
      </div>
    ),
    ['OutboundCall']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Outbound Call">call_made</Icon>
      </div>
    ),
    ['Ausgehender Anruf']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Outgoing Call">call_made</Icon>
      </div>
    ),
    ['Lokal belegt (TAPI)']: (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon>call_received</Icon>
      </div>
    ),
  };

  if (!statusMap[statusName]) {
    return (
      <div className={clsx(classes.iconStyle, 'red')}>
        <Icon aria-label="Local busy TAPI">call_received</Icon>
      </div>
    );
  }
  return statusMap[statusName];
};

const AvatarBadge = (props) => {
  const classes = useStyles();

  return (
    <Badge
      badgeContent={statusIcon(
        props.statusName,
        props.classes ? props.classes : classes
      )}
    >
      {props.children}
    </Badge>
  );
};
export default AvatarBadge;
