import React from 'react';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight    : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    transition   : theme.transitions.create('all'),
    '& i'        : {
      transition   : theme.transitions.create('all'),
      marginRight: 5,
      color      : `${theme.palette.secondary.main} !important`
    },
    '&:hover': {
      color          : theme.palette.secondary.dark,
      borderColor    : theme.palette.secondary.dark,
      backgroundColor: 'transparent',
      '& i'          : {
        color: `${theme.palette.secondary.light} !important`
      },
    },
  },
  rootNoHover: {
    minHeight    : theme.dimensions.inputHeight,
    letterSpacing: theme.dimensions.letterSpacing,
    borderRadius : theme.dimensions.borderRadiusInput,
    transition   : theme.transitions.create('all'),
    '& i'        : {
      transition   : theme.transitions.create('all'),
      marginRight: 5,
      color      : `${theme.palette.secondary.main} !important`
    },
  },
  disabled: {
    '& i': {
      color: `${theme.palette.text.disabled} !important`
    },
  },
  outlinedSecondary: {
    border: `1px solid ${theme.palette.secondary.main}`
  }
}));

const SecondaryButton = props => {
  const classes = useStyles();

  const getRootClass = () => {
    return (props?.color && !['primary','secondary'].includes(props.color) ?
      classes.rootNoHover : classes.root);
  };

  return (
    <Button classes={{ root: getRootClass(), disabled: classes.disabled, outlinedSecondary: classes.outlinedSecondary }} variant="outlined" color="secondary" {...props}>
      {props.children}
    </Button>
  );
};

export default SecondaryButton;
