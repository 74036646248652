import { withStyles } from '../styles';
import {
  Accordion as MuiExpansionPanel,
  AccordionSummary  as MuiExpansionPanelSummary,
  AccordionDetails as MuiExpansionPanelDetails,
} from '@material-ui/core';

export const ExpansionPanel = withStyles(muiTheme => ({
  root: {
    backgroundColor: muiTheme.palette.neutral[400],
    boxShadow      : 'none',
    '&::before'    : {
      backgroundColor: 'transparent',
    },
  },
  rounded: {
    borderRadius: muiTheme.dimensions.borderRadiusPanel,
  },
  expanded: {
    margin: '0 !important',
  },
}))(MuiExpansionPanel);

export const ExpansionPanelSummary = withStyles(muiTheme => ({
  content: {
    fontWeight: muiTheme.typography.fontWeightMedium,
    padding   : muiTheme.spacing(0, 1),
  },
  expanded: {
    maxHeight: muiTheme.dimensions.inputHeight,
    minHeight: 'auto !important',
    margin   : 0,
  },
  root: {
    '& p': {
      fontWeight: muiTheme.typography.fontWeightMedium,
    },
    '& div:nth-child(2)': {
      padding    : muiTheme.spacing(1),
      marginRight: '-5px',
    },
    maxHeight: muiTheme.dimensions.inputHeight,
    minHeight: 'auto',
    padding  : muiTheme.spacing(0, 1),
  },
}))(MuiExpansionPanelSummary);

export const ExpansionPanelDetails = withStyles(muiTheme => ({
  root: {
    backgroundColor: muiTheme.palette.background.paper,
    padding        : 0,
    '& > div'      : {
      width: '100%'
    }
  },
}))(MuiExpansionPanelDetails);
