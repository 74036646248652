import React, { forwardRef } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor                                     : theme.palette.background.paper,
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
  },
  input: {
    borderRadius   : theme.dimensions.borderRadiusInput,
    backgroundColor: theme.palette.background.paper,
    '&:disabled'   : {
      backgroundColor: 'transparent'
    }
  },
  inputMarginDense: {
    paddingTop   : '8.5px',
    paddingBottom: '8.5px',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  disabled: {
    backgroundColor: theme.palette.secondary[50],
  },
  error     : {},
  adornedEnd: {
    paddingRight: 9,
  },
  marginDense: {
    transform: 'translate(14px, 10px) scale(1) !important',
  },
  shrink: {
    transform: 'translate(14px, -6px) scale(0.75) !important',
  },
  labelDisabled: {
    color: `${theme.palette.grey[800]} !important`,
  },
}));

const TextInputField = ({ validationIcon, ...props }, ref) => {
  const classes = useStyles(props);

  const endAdornment = validationIcon ? (
    <InputAdornment position="end" style={{ width: 16 }}>
      {validationIcon}
    </InputAdornment>
  ) : (
    <span id="end-adornment-placeholder" style={{ width: 24 }}></span>
  );

  return (
    <TextField
      {...props}
      type="text"
      onChange={props.onChange}
      label={props.name}
      placeholder={props.placeholder}
      color="secondary"
      classes={{ ...props.classes }}
      margin="dense"
      variant="outlined"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      inputRef={ref}
      InputLabelProps={{
        classes: {
          shrink     : classes.shrink,
          marginDense: classes.marginDense,
          disabled   : classes.labelDisabled,
        },
      }}
      InputProps={{
        classes: {
          root            : classes.root,
          input           : classes.input,
          inputMarginDense: classes.inputMarginDense,
          notchedOutline  : classes.notchedOutline,
          focused         : classes.focused,
          disabled        : classes.disabled,
          error           : classes.error,
          adornedEnd      : classes.adornedEnd,
        },
        endAdornment,
      }}
    ></TextField>
  );
};

export default forwardRef(TextInputField);
