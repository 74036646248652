import React, { forwardRef } from 'react';
import { Menu as MuiMenu } from '@material-ui/core';

const Menu = forwardRef((props, ref) => {
  return (
    <MuiMenu
      ref={ref}
      {...props}
      MenuListProps={{ style: { display: 'flex', flexDirection: 'column' } }}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      {props.children}
    </MuiMenu>
  );
});

export default Menu;
