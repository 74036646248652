import React from 'react';
import ValidatedField from './ValidatedField';
/**
 * Decimal Number with one after comma digit
 * @param {*} props
 */
const Decimal1Field = props => {
  return <ValidatedField {...props} expression={/^[0-9]*,[0-9]$/gm} />;
};

export default Decimal1Field;
