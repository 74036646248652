import { Dialog as MuiDialog } from '@material-ui/core';
import React from 'react';
import { useTheme, makeStyles } from '../../styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
  root: {
    transition    : theme.transitions.create('all'),
    background    : alpha(theme.palette.neutral.light, 0.4),
    backdropFilter: 'blur(2px)',
    '& > div'     : {
      backgroundColor: 'transparent',
    },
  },
}));

const Dialog = props => {
  const theme = useTheme();
  const classes = useStyles();

  return (
    <MuiDialog
      {...props}
      maxWidth={false}
      classes={{ root: classes.root, ...props.classes }}
      PaperProps={{
        style: {
          boxShadow: theme.dimensions.shadowDialog,
        },
      }}
    >
      {props.children}
    </MuiDialog>
  );
};

export default Dialog;
