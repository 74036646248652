import React from 'react';
import PrimaryButton from './PrimaryButton';
import EmptyModule from './EmptyModule';

const BoundaryContent = props => {
  return (
    <EmptyModule
      {...props}
      icon="block"
      message={props.message || 'Fehler beim Laden der Komponente'}
    >
      {!props.hideButton && (
        <PrimaryButton onClick={() => props.setError(false)}>Erneut versuchen</PrimaryButton>
      )}
    </EmptyModule>
  );
};

// dont convert this to Functional Component until it is available as Hook
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line
    console.error(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <BoundaryContent
          {...this.props}
          setError={bool => this.setState({ hasError: bool })}
        ></BoundaryContent>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
