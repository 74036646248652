import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Stepper as MuiStepper,
  Step as MuiStep,
  StepLabel as MuiStepLabel,
  StepContent as MuiStepContent,
} from '@material-ui/core';

const useStylesStepper = makeStyles(muiTheme => ({
  root: {
    padding        : 0,
    backgroundColor: muiTheme.palette.neutral.A100,
  },
}));

export const Stepper = props => {
  const classes = useStylesStepper();
  return (
    <MuiStepper classes={{ root: classes.root }} {...props}>
      {props.children}
    </MuiStepper>
  );
};

export const Step = props => {
  //   const classes = useStyles();
  return <MuiStep {...props}>{props.children}</MuiStep>;
};

const useStylesStepLabel = makeStyles(muiTheme => ({
  iconContainer: {
    '& > svg': {
      fontSize  : '2rem',
      '& > text': {
        fontSize  : muiTheme.typography.size.m,
        fontWeight: muiTheme.typography.fontWeightMedium,
      },
    },
  },
  labelContainer: {
    '& > span': {
      fontSize: muiTheme.typography.size.m,
    },
  },
}));

export const StepLabel = props => {
  const classes = useStylesStepLabel();
  return (
    <MuiStepLabel
      {...props}
      classes={{ iconContainer: classes.iconContainer, labelContainer: classes.labelContainer }}
    >
      {props.children}
    </MuiStepLabel>
  );
};

export const StepContent = props => {
  //   const classes = useStyles();
  return <MuiStepContent {...props}>{props.children}</MuiStepContent>;
};
