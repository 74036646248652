import React, { useRef } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import ValidatedField from './ValidatedField';
import DateTimePicker from '../DateTimePicker';
import { mergeRefs } from '../../api/util/mergeRefs';

const useStyles = makeStyles(() => ({
  picker: {
    zIndex         : '9999 !important',
    position       : 'absolute',
    backgroundColor: '#fff',
  }
}));

const isDateValid = (str, format) => moment(str, format, true).isValid();

const ValidatedDatepicker = React.forwardRef((props, ref) => {
  const elementRef = useRef(null);
  const classes = useStyles();
  const { format, showTimeSelect, showTimeSelectOnly, useDatepicker, ...fieldConfig } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const [date, setDate] = React.useState(isDateValid(props.value, format) ? props.value : null);
  const [dateFormatted, setDateFormatted] = React.useState(props.value);

  const formatDate = (value) => moment(value).format(format);

  const handleClick = (e) => {
    if (props.disabled) return;
    e.preventDefault();
    setIsOpen(true);
  };

  const handleDatepickerChange = (value) => {
    if (!elementRef?.current) return;
    elementRef.current.value = formatDate(value);
    elementRef.current.dispatchEvent(new CustomEvent('forceChange'));
  };

  const handleTypedChange = (event, hasError) => {
    const value = String(event.target.value);
    setDateFormatted(value);
    // as this condition is true when input field is empty
    if (!hasError) {
      // here we have to check value before constructing Date object
      setDate(value && isDateValid(value, format) ? moment(value, format).toDate() : null);
    }
    props.onChange(event, hasError);
  };

  return (
    <>
      <div>
        <ValidatedField
          {...fieldConfig} // normal props where giving unregonized prop warning
          ref={mergeRefs([ref, elementRef])}
          value={dateFormatted}
          onClick={handleClick}
          onChange={handleTypedChange}
          expression={props.expression}
        />
        {(useDatepicker && isOpen) && (
          <DateTimePicker
            selected={date}
            dateFormat={format}
            timeFormat={'HH:mm'}
            showTimeSelect={showTimeSelect}
            showTimeSelectOnly={showTimeSelectOnly}
            onChange={handleDatepickerChange}
            calendarClassName={classes.picker}
            onClickOutside={() => setIsOpen(false)}
            inline
          />
        )}
      </div>
    </>
  );
});

export default ValidatedDatepicker;
