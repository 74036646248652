import React from 'react';
import Icon from './Icon';
import { TextField, MenuItem, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export default function SelectComponent(props) {
  const classes = useStyles();

  const { options, valueName, displayName, selected = undefined, validationIcon, ...rest } =
    props;
  let optionList = [];
  let value = '';

  if (options) {
    optionList = options.map((option) => ({
      value: option[valueName],
      label: option[displayName],
    }));
  }

  if (selected !== undefined) {
    const findSelection = optionList.find(
      (option) => option.value === selected
    );
    value = findSelection ? findSelection.value : '';
  }

  const menuProps = {
    anchorOrigin: {
      vertical  : 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical  : 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    classes           : {
      paper: classes.menuPaper,
    },
  };

  const endAdornment = (
    <InputAdornment position="end" style={{ marginLeft: 0 }}>
      <Icon styles={{}} key="1">
        expand_more
      </Icon>
      {validationIcon && validationIcon}
    </InputAdornment>
  );

  return (
    <TextField
      {...rest}
      fullWidth
      select
      disabled={props.disabled}
      name={props.name}
      placeholder={props.placeholder}
      variant="outlined"
      margin="dense"
      color="secondary"
      onChange={(e) => props.onChange(e.target)}
      value={value}
      data-element={props['data-element']}
      className={classes.marginDense}
      InputProps={{
        classes: {
          root            : classes.root,
          input           : classes.input,
          inputMarginDense: classes.inputMarginDense,
          notchedOutline  : classes.notchedOutline,
          focused         : classes.focused,
          disabled        : classes.disabled,
          error           : classes.error,
          adornedEnd      : classes.adornedEnd,
        },
        endAdornment,
      }}
      SelectProps={{
        MenuProps    : menuProps,
        IconComponent: () => null,
      }}
    >
      {optionList.map((opt, pos) => (
        <MenuItem
          key={pos}
          data-cy={`select-option-${opt.label}`}
          value={opt.value}
        >
          {opt.label}
        </MenuItem>
      ))}
    </TextField>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor                                     : theme.palette.background.paper,
    '&:hover:not($disabled):not($error) $notchedOutline': {
      borderColor: theme.palette.primary.main,
    },
    '&$disabled $notchedOutline': {
      borderColor    : '#bdbdbd',
      backgroundColor: 'rgb(0 0 0 / 8%) !important',
    },
    '& i:nth-child(2)': {
      position     : 'absolute',
      top          : 'calc(50% - 8px)',
      right        : '30px !important',
      pointerEvents: 'none',
    },
    '& i': {
      position     : 'absolute',
      top          : 'calc(50% - 8px)',
      right        : '10px',
      pointerEvents: 'none',
    },
    '& fieldset > legend': {
      display: 'block',
    },
  },
  input: {
    borderRadius   : theme.dimensions.borderRadiusInput,
    backgroundColor: theme.palette.background.paper,
  },
  inputMarginDense: {
    paddingTop   : '8.5px',
    paddingBottom: '8.5px',
  },
  notchedOutline: {
    borderColor: theme.palette.secondary.main,
    transition : theme.transitions.create('border-color'),
    '&:hover'  : {
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {
    backgroundColor    : theme.palette.background.paper,
    '& $notchedOutline': {
      borderWidth: '1px !important',
      borderColor: theme.palette.primary.main + '!important',
    },
  },
  disabled: {
  },
  error    : {},
  menuPaper: {
    borderRadius      : 0,
    transitionDuration: '0s !important',
  },
  marginDense: {
    margin: 0,
  },
  adornedEnd: {
    paddingRight: 0,
  },
}));
