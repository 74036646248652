import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(muiTheme => ({
  padding: {
    padding: muiTheme.dimensions.moduleSpacing,
    height : '100%',
  },
}));

const ModuleContainer = props => {
  const classes = useStyles();
  return (
    <div className={classes.padding} {...props}>
      {props.children}
    </div>
  );
};

export default ModuleContainer;
