import React from 'react';
import ReactTooltip from 'react-tooltip';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  tooltip: {
    background  : theme.palette.secondary.main + '!important',
    borderRadius: theme.dimensions.borderRadiusInput + '!important',
    opacity        : 1,
    padding        : theme.spacing(0.5, 1) + '!important',
    fontSize       : theme.typography.size.xs + '!important',
    '&::after'     : {
      borderTopColor  : theme.palette.secondary.main + '!important',
      borderRightColor: theme.palette.secondary.main + '!important',
    },
    '&.place-right::after': {
      left: 0 + '!important'
    },
  },
}));

const Tooltip = props => {
  const classes = useStyles();
  return (
    <ReactTooltip className={classes.tooltip} effect={props.effect || 'solid'} {...props}>{props.children}</ReactTooltip> );
};

export default Tooltip;
