import React from 'react';
import { DialogContent as MuiDialogContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((muiTheme) => ({
  root: {
    fontSize: muiTheme.typography.size.m,
    padding : muiTheme.spacing(3)
  },
}));

const DialogContent = props => {
  const classes = useStyles();
  return (
    <MuiDialogContent className={classes.root} {...props}>
      {props.children}
    </MuiDialogContent>
  );
};

export default DialogContent;
