import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@material-ui/core/styles/colorManipulator';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Menu } from '@material-ui/core/';
import { Avatar, Icon, IconButton, List, ListItem, ListItemText, ListItemIcon, MenuItem, Drawer, Tooltip } from '..';

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  favicon: {
    padding: 0 + '!important'
  },
  iconButton: {
    '&:hover': {
      backgroundColor: `${theme.palette.blue[900]} !important`,
      borderRadius   : theme.dimensions.borderRadiusPanel,
    },
    borderRadius: theme.dimensions.borderRadiusPanel,
  },
  icon: {
    color   : `${theme.palette.common.white} !important`,
    fontSize: 20,
  },
  avatar: {
    padding         : 0 + '!important',
    '& > span > div': {
      backgroundColor: alpha(theme.palette.neutral[700], 0.2)
    }
  },
  logo: {
    marginBottom: theme.spacing(3),
    height      : theme.spacing(5),
    fill        : theme.palette.primary.main
  },
  menu: {
    '& div:last-child li': {
      border: 0,
    },
  },
  root: {
    boxShadow: theme.dimensions.shadowGlobalNavigation,
    height   : '100%',
    left     : 0,
    padding  : theme.spacing(3, 0),
    right    : 'auto',
    width    : theme.dimensions.globalNavigationWidth,
    zIndex   : theme.zIndex.drawer + 1,
  },
  colorSecondary: {
    backgroundColor: theme.palette.blue.dark,
  },
  tooltip: {
    '& > div': {
      marginLeft: `${theme.spacing(2)}px !important`
    }
  },
  toolbar: {
    flexDirection: 'column',
    minHeight    : '100%',
  },
  drawerRoot: {
    zIndex: `${theme.zIndex.drawer} !important`
  },
  paperAnchorLeft: {
    left: theme.spacing(8)
  },
  paper: {
    boxShadow      : theme.dimensions.shadowDialog,
    backgroundColor: theme.palette.blue[900],
    color          : theme.palette.common.white,
    overflowY      : 'auto',
    width          : theme.spacing(30),
    padding        : theme.spacing(4, 3, 4, 3),
    zIndex         : theme.zIndex.drawer
  },
  items: {
    '& > li': {
      fontSize : '1.2rem',
      '&:hover': {
        backgroundColor: theme.palette.blue.dark,
      },
      borderBottom: 0,
      borderRadius: theme.dimensions.borderRadiusPanel,
    },
    marginBottom: theme.spacing (4),
  },
  title: {
    color        : theme.palette.primary.main,
    fontSize     : theme.typography.size.m,
    fontWeight   : theme.typography.fontWeightBold,
    letterSpacing: theme.dimensions.letterSpacing,
    paddingLeft  : theme.spacing(1.5),
    textTransform: 'uppercase'
  },
}));


const GlobalNavigation = (props) => {
  const {
    onClickFavicon,
    onClickHome,
    homeVisible,
    mainMenuVisible,
    mainMenu,
    buttons,
    avatar,
    avatarMenuItems,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const [menuVisible, setMenuVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);

  function handleDrawerToggle() {
    setMenuVisible(!menuVisible);
  }

  const handleMenu = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <AppBar position="fixed" color="secondary" classes={{ root: classes.root, colorSecondary: classes.colorSecondary }}>
        <Toolbar disableGutters className={classes.toolbar}>
          <div className={classes.tooltip}>
            <Tooltip place="right" />
          </div>
          {/* Buttons oben */}
          <IconButton onClick={onClickFavicon} className={classes.favicon}>
            <svg className={classes.logo} x="0px" y="0px" viewBox="0 0 250.6 250.6" >
              <g>
                <path className="st0" d="M207.4,98.8h-61.2c-2.2,0-4.7,1.7-5.5,3.6l-18.9,46.2c-0.7,1.7,0.2,3.1,2,3.1h62.5c1.8,0,3.9-1.4,4.6-3.1
		l19-46.2C210.7,100.5,209.6,98.8,207.4,98.8"/>
                <path className="st0" d="M126.2,98.8H65c-2.2,0-4.7,1.7-5.5,3.6l-18.9,46.2c-0.7,1.7,0.2,3.1,2,3.1H105c1.8,0,3.9-1.4,4.6-3.1l19-46.2
		C129.5,100.5,128.4,98.8,126.2,98.8"/>
                <path className="st0" d="M207.4,214.6h-61.2c-2.2,0-4.7-1.7-5.5-3.6l-18.9-46.2c-0.7-1.7,0.2-3.1,2-3.1h62.5c1.8,0,3.9,1.4,4.6,3.1
		l19,46.2C210.7,213,209.6,214.6,207.4,214.6"/>
                <path className="st0" d="M126.2,88.9H65c-2.2,0-4.7-1.7-5.5-3.6L40.6,39.1c-0.7-1.7,0.2-3.1,2-3.1H105c1.8,0,3.9,1.4,4.6,3.1l19,46.2
		C129.5,87.3,128.4,88.9,126.2,88.9"/>
              </g>
            </svg>
          </IconButton>
          {homeVisible ? (
            <IconButton
              aria-label="Home"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              className={classes.iconButton}
              onClick={onClickHome}
              data-tip="Home"
            >
              <Icon className={classes.icon}>home</Icon>
            </IconButton>
          ) : null}
          {mainMenuVisible ? (
            <>
              <IconButton
                aria-label="Menu"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                className={classes.iconButton}
                onClick={handleDrawerToggle}
                data-tip="Menu"
              >
                <Icon className={classes.icon}>menu</Icon>
              </IconButton>
              <Drawer
                variant="temporary"
                anchor="left"
                open={menuVisible}
                onClose={handleDrawerToggle}
                classes={{root: classes.drawerRoot, paperAnchorLeft: classes.paperAnchorLeft, paper: classes.paper}}
                BackdropProps={{ invisible: true }}
              >
                {mainMenu?.map(menu => (
                  <Fragment key={menu.id} >
                    <h2 className={classes.title}>{menu.title}</h2>
                    <List className={classes.items}>
                      {
                        menu.items.map (
                          item => (
                            <ListItem
                              key={item.id}
                              onClick={() => {
                                if (item.link) item.link();
                                handleDrawerToggle();
                              }}
                            >
                              {item.name}
                            </ListItem>
                          )
                        )
                      }
                    </List>
                  </Fragment>
                ))
                }
              </Drawer>
            </>
          ) : null}
          {buttons?.map((button) => (
            <IconButton
              aria-label="button"
              className={classes.iconButton}
              key={button.id}
              onClick={button.onClick}
              data-tip={button.label}
            >
              <Icon className={classes.icon}>{button.icon}</Icon>
            </IconButton>
          ))}
          <div className={classes.grow} />
          {/* Buttons unten */}
          <IconButton
            ref={buttonRef}
            aria-label="button"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.avatar}
          >
            <Avatar>{avatar}</Avatar>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={buttonRef.current}
            getContentAnchorEl={null}
            keepMounted
            transformOrigin={{
              horizontal: 'left',
              vertical  : 'top',
            }}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                boxShadow : theme.dimensions.shadowDialog,
                marginLeft: 42,
                minWidth  : 200,
              },
            }}
            className={classes.menu}
          >
            {avatarMenuItems?.map((item) => (
              <MenuItem
                component="div"
                style={{ padding: 0 }}
                key={item.id}
                onClick={() => {
                  if (item.onClick) item.onClick();
                  setOpen(false);
                }}
              >
                <ListItem>
                  <ListItemIcon>
                    <Icon>{item.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText>{item.label}</ListItemText>
                </ListItem>
              </MenuItem>
            ))}
          </Menu>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

GlobalNavigation.propTypes = {
  onClickFavicon  : PropTypes.func,
  onClickHome     : PropTypes.func,
  homeVisible    : PropTypes.bool,
  mainMenuVisible: PropTypes.bool,
  mainMenu        : PropTypes.array,
  buttons         : PropTypes.array,
  avatar          : PropTypes.any,
  avatarMenuItems: PropTypes.array
};


GlobalNavigation.defaultProps = {
  homeVisible    : true,
  mainMenuVisible: true,
  mainMenu        : [],
  buttons         : [],
  avatarMenuItems: []
};

export default GlobalNavigation;
